<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-id v-model="model.id" field-name="id" label="id" :error="error.id" disabled />
        <ns-company v-model="model.companyId" :error="error.companyId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.language" field-name="language" enum-name="Language" label="language" :error="error.language" disabled />
        <n-field-enum v-model="model.reportType" field-name="reportType" enum-name="CustomerReportType" label="reportType" :error="error.reportType" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-id v-model="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" disabled />
        <n-field-id :value="model.accountId" field-name="accountId" label="accountId" :error="error.accountId" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-date v-model="model.issueDate" field-name="issueDate" label="issueDate" :error="error.issueDate" disabled />
        <n-field-enum v-model="model.issueStatus" field-name="issueStatus" enum-name="IssueStatus" label="issueStatus" :error="error.issueStatus" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-datetime v-model="model.issueTime" field-name="issueTime" label="issueTime" :error="error.issueTime" disabled />
        <n-field-enum v-model="model.receiptRoute" field-name="receiptRoute" enum-name="TraceRoute" label="receiptRoute" :error="error.receiptRoute" disabled />
      </n-row-col2>
      <n-row-col2>
        <n-field-datetime v-model="model.stageTime" field-name="stageTime" label="stageTime" :error="error.stageTime" disabled />
        <n-field-enum v-model="model.stageStatus" field-name="stageStatus" enum-name="StageStatus" label="stageStatus" :error="error.stageStatus" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-datetime v-model="model.receiptTime" field-name="receiptTime" label="receiptTime" :error="error.receiptTime" disabled />
        <n-field-enum v-model="model.receiptStatus" field-name="receiptStatus" enum-name="ReceiptStatus" label="receiptStatus" :error="error.receiptStatus" disabled />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { update, validate } from '@/api/customer/customer-customer-report';

export default NModal.extend({
  name: 'ModalCustomerReport',
  components: { NModal },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return update(model);
    },
  },
});
</script>
