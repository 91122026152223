<template>
  <!-- prettier-ignore -->
  <index-content>
    <template #options>
      <n-button ghost custom-icon="fa fa-info-circle" text="summary" @on-click="doSummary" />
    </template>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <n-field-id v-model="condition.customerId" label="customerId" />
      <n-field-id v-model="condition.accountId" label="accountId" />
      <n-field-enum v-model="condition.type" enum-name="CustomerReportType" label="type" all-option />
      <n-field-date v-model="condition.from" label="from" />
      <n-field-date v-model="condition.to" label="to" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-1">{{ $t('label.customerId') }}</th>
            <th class="col-weight-1">{{ $t('label.accountId') }}</th>
            <th class="col-weight-1">{{ $t('label.issueStatus') }}</th>
            <th class="col-weight-1">{{ $t('label.stageStatus') }}</th>
            <th class="col-weight-4" :title="$t('label.reportTitle')">{{ $t('label.reportTitle') }}</th>
            <th class="n-table-date">{{ $t('label.issueDate') }}</th>
            <th class="n-table-timestamp">{{ $t('label.issueTime') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td class="n-ellipsis">
              <n-modal-link name="customer.profile" :value="row.customerId" />
            </td>
            <td class="n-ellipsis">
              <n-modal-link name="customer.account" :value="row.accountId" />
            </td>
            <td v-enums:IssueStatus="row.issueStatus"></td>
            <td v-enums:StageStatus="row.stageStatus"></td>
            <td class="n-ellipsis">
              <a v-if="reportUrl(row.id) && row.issueStatus === $enums.IssueStatus.SUCCESS" :href="reportUrl(row.id)" download @click="doDownload($event, row)">
                <span v-enums:CustomerReportTypeName="row.reportType"></span>
              </a>
              <span v-else v-enums:CustomerReportTypeName="row.reportType"></span>
            </td>
            <td>{{ row.issueDate }}</td>
            <td>{{ row.issueTime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="openModal(row)" />
              <nb-confirm custom-icon="fas fa-sync" text="retry" @on-ok="doRetry(row.id)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import ModalCustomerReport from './ModalCustomerReport';
import { download } from '@/components/common/button/NbDownload';
import { fetch, retry, reportUrl } from '@/api/customer/customer-customer-report';

export default CustomerView.extend({
  name: 'CustomerCustomerReport',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        type: 0,
        accountId: null,
        companyId: null,
        customerId: null,
        from: this.prevTradingDate,
        to: this.prevTradingDate,
      },
    };
  },
  methods: {
    reportUrl,

    doRetry(id) {
      return retry(id);
    },

    parse(rows) {
      this.records = rows.records;
    },

    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },

    doSummary() {
      this.pushMenu({ url: `/customer/customer/report/summary` });
    },

    openModal(model = {}) {
      this.createModal(ModalCustomerReport, { model, on: this });
    },

    doDownload(e, report) {
      e.preventDefault();
      let url = reportUrl(report.id);
      if (url) {
        download(url);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~@/style/table';

.n-table {
  min-width: 1200px;

  th.rw-action {
    width: 8.5rem;
  }

  th.ro-action {
    width: $action-detail;
  }
}
</style>
