import { $fetch, $post } from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/reports`, params);
}

export function validate(model) {
  return $post(`customer/report/validate`, model);
}

export function update(model) {
  return $post(`customer/report/${model.id}`, model);
}

export function retry(id) {
  return $post(`customer/retry/${id}`);
}

export function reportUrl(id) {
  return id ? `customer/report/download?reportId=${id}` : undefined;
}
